<template>
  <the-wrapper :isSpinner="isSpinner">
    <base-table :fields="columns" :items="rowsWithPagination" :isFooter="true">
      <template #headerText>
        {{ `Голосовая рассылка за ${urlQuery.StartDateTimeStr} - ${urlQuery.EndDateTimeStr}` }}
      </template>
      <template #footer>
        <b-pagination
          v-if="isPagination"
          v-model="currentPage"
          :total-rows="table.Rows[0].RowsTotal"
          :per-page="rowsPerPage"
          align="center"
          size="Default"
          class="my-0"
          @input="showPageData()"
        ></b-pagination>
      </template>
    </base-table>
  </the-wrapper>
</template>

<script>
import BaseTable from '@/components/shared/BaseTable.vue';
import TheWrapper from '@/components/shared/TheWrapper.vue';
import { sendCustomerRequest } from '@/api/common';
import { catchFunction } from '@/additional_helpers/helpers';
import URL from '@/basUrl';
import { mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      isSpinner: true,
      urlQuery: this.$route.query,
      currentPage: 1,
      rowsPerPage: 100
    };
  },

  components: {
    TheWrapper,
    BaseTable
  },

  created() {
    if (this.urlQuery.Filter) this.urlQuery.Filter = decodeURIComponent(this.urlQuery.Filter);

    sendCustomerRequest(URL.REPORT, JSON.stringify(this.urlQuery))
      .then((result) => this.SET_TABLE(JSON.parse(result.text)))
      .then(() => this.SHOW_LOADING(false))
      .catch((e) => catchFunction(e));
  },

  beforeDestroy() {
    this.CLEAR_TABLE(true);
  },

  computed: {
    ...mapGetters({
      rows: 'reports/rows',
      columns: 'reports/columns',
      table: 'reports/table'
    }),

    rowsWithPagination() {
      return this.showPageData();
    },

    isPagination() {
      return this.table.Rows[0].RowsTotal >= this.rowsPerPage;
    }
  },

  methods: {
    ...mapMutations({
      SET_DATA: 'SET_DATA',
      SHOW_LOADING: 'SHOW_LOADING',
      SET_TABLE: 'reports/SET_TABLE',
      CLEAR_TABLE: 'reports/CLEAR_TABLE'
    }),

    showPageData() {
      const START = (this.currentPage - 1) * this.rowsPerPage;
      const END = this.currentPage * this.rowsPerPage;
      if (this.rows.rowsData) return this.rows.rowsData.slice(START, END);
      return this.rows.rowsData;
    }
  }
};
</script>

<style></style>
